const custom_config = {
    crm_url: 'https://portal.kiyocrmondemand.com/',
};

const portal_config = {
    crm_url: custom_config.crm_url + '/ba_api',
    portal_url: '',
    layout: 'main',
};

export default portal_config;
